<template>
  <div class="carrier">
    <!-- Loading Spinner -->
    <Spinner :spin="isProcessing" />
    <!-- Header -->
    <section class="head">
      <div class="left">
        <a v-if="!isLineCode" @click.prevent="goTo('/profile')">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span>手機載具</span>
      <div class="right"></div>
    </section>
    <section class="main">
      <!-- ********** 綁定前 ********** -->
      <ValidationObserver v-if="!linkedCarrier" slim v-slot="{ valid, passes }">
        <fragment>
          <div class="inputs">
            <div class="input">
              <span>電話號碼</span>
              <input
                type="text"
                placeholder=""
                v-model="userData.phoneNumber"
                disabled
              />
            </div>
            <ValidationProvider
              slim
              rules="required|isCarrierBarcode"
              v-slot="{ invalid, errors }"
            >
              <div class="input">
                <span>載具條碼</span>
                <input
                  :class="{ error: invalid && errors[0] }"
                  type="text"
                  placeholder=""
                  v-model="userData.cardNo"
                />
                <span class="error">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
            <!-- 暫時不用驗證碼-->
            <ValidationProvider
              v-if="false"
              slim
              rules="required"
              v-slot="{ invalid, errors }"
            >
              <div class="input">
                <span>載具驗證碼</span>
                <input
                  :class="{ error: invalid && errors[0] }"
                  type="password"
                  placeholder=""
                  v-model="cardEncrypt"
                />
                <span class="error">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <a
            v-if="false"
            class="forget"
            href="https://www.einvoice.nat.gov.tw/APCONSUMER/BTC511W/"
            >忘記驗證碼</a
          >
          <button @click.prevent="save(valid, passes)">
            <span>綁定</span>
          </button>
          <span class="comment"
            >載具條碼請至
            <a href="https://www.einvoice.nat.gov.tw/APCONSUMER/BTC501W/">
              財政部電子發票整合服務平台</a
            >進行申請</span
          >
        </fragment>
      </ValidationObserver>

      <!-- ********** 綁定後 ********** -->
      <fragment v-else>
        <font-awesome-icon
          icon="fa-regular fa-circle-check"
          size="5x"
        ></font-awesome-icon>
        <span class="title">載具綁定成功</span>
        <span class="content"
          >現在可於會員頁面上方<br />「會員條碼」切換「載具條碼」</span
        >
        <!-- <a class="forget" @click.prevent="popDelete">解除綁定</a> -->
        
        <a v-if="isLineCode" class="forget" @click.prevent="goTo('/line-code')">回首頁</a>
        <a v-else class="forget" @click.prevent="goTo('/home')">回首頁</a>
      </fragment>
    </section>

    <!-- 訊息popup -->
    <section id="popNotice" class="pop notice" @click="popClose">
      <div class="popBody" @click.stop>
        <div>
          <div class="icon">
            <font-awesome-icon
              v-if="popupContent.type === 'success'"
              icon="fa-regular fa-circle-check"
              size="5x"
            ></font-awesome-icon>
            <font-awesome-icon
              v-else-if="popupContent.type === 'error'"
              icon="fa-regular fa-circle-xmark"
              size="5x"
            ></font-awesome-icon>
            <div class="orange" v-else>
              <font-awesome-icon
                icon="fa-solid fa-exclamation"
                size="3x"
              ></font-awesome-icon>
            </div>
          </div>
          <span class="title">{{ popupContent.title }}</span>
          <span>{{ popupContent.message }}</span>
        </div>
        <div class="buttons">
          <button v-if="popupContent.type === 'notice'" @click="popClose">
            <span>取消</span>
          </button>
          <button @click="popupContent.handleConfirmBtnClick">
            <span>確認</span>
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: '欄位必填',
});

export default {
  name: 'CarrierEdit',
  components: {
    Spinner,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      userData: {
        // name: '',
        phoneNumber: '',
        cardNo: '',
      },
      isProcessing: false,
      cardEncrypt: '',
      linkedCarrier: null,
      popupContent: {
        type: '',
        title: '',
        message: '',
        handleConfirmBtnClick: '',
      },
    };
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    merchantId() {
      return this.user.user?._json?.merchantId || this.userInfo.merchantId;
    },
    isLineCode () {
      return this.$route.query.linecode === 'true';
    }
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    goTo(path) {
      let query = this.$route.query;
      if (query.linecode) {
        delete this.$route.query.linecode
      }
      this.$router.push({ path, query: this.$route.query });
    },
    callUserDataAPI() {
      // 一進來就強制把cardNo reset..
      // let data = Object.assign({}, this.userData);
      // data.cardNo = data.cardNo ? data.cardNo.trim() : null;
      // data.cardEncrypt = this.cardEncrypt;
      var config = {
        method: 'get',
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
        // data
      };

      return this.$http(config)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getUserData() {
      this.callUserDataAPI()
        .then(res => {
          this.$store.commit('updateUserInfo', res.data);
          this.userData = res.data;
          this.linkedCarrier = res.data.cardNo;
          console.log('getUserData', res.data);
        })
        .catch(err => {
          console.log('err: ', err);
          this.goTo('/error');
        });
    },
    popNotice(content) {
      if (!content.handleConfirmBtnClick)
        content.handleConfirmBtnClick = this.popClose;
      this.popupContent = content;
      popNotice.style.display = 'flex';
    },
    popClose() {
      popNotice.style.display = 'none';
    },
    popDelete() {
      this.popNotice({
        type: 'notice',
        title: '確定解除綁定載具？',
        message: '解除綁定後部分功能將無法正常使用',
        handleConfirmBtnClick: this.removeCarrier,
      });
    },
    removeCarrier() {
      this.isProcessing = true;
      this.popClose();
      let data = Object.assign({}, this.userData);
      data.cardNo = null;
      data.cardEncrypt = null;
      let config = {
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
        method: 'PUT',
        data: data,
      };
      this.$http(config)
        .then(res => {
          console.log('res: ', res);
          this.popNotice({
            type: 'success',
            title: '載具解綁成功',
          });
          this.getUserData();
          this.cardEncrypt = '';
        })
        .catch(err => {
          console.log('err: ', err);
          this.popNotice({
            type: 'error',
            title: '載具解綁失敗',
            message: '網路錯誤，請稍後重試',
          });
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
    callUpdateProfileAPI() {
      let data = Object.assign({}, this.userData);
      data.cardNo = data.cardNo.trim();
      data.cardEncrypt = this.cardEncrypt;
      let config = {
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
        method: 'PUT',
        data: data,
      };
      console.log('config: ', config);
      return this.$http(config);
    },
    save(valid, passes) {
      passes();
      if (!valid) return;
      this.isProcessing = true;
      this.callUpdateProfileAPI()
        .then(res => {
          console.log('res: ', res);
          this.getUserData();
        })
        .catch(err => {
          console.log('err: ', err);
          this.popNotice({
            type: 'error',
            title: '載具綁定失敗',
            message: '網路錯誤，請稍後重試',
          });
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
  },
};
</script>
